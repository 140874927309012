import React from "react";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { Video } from "@ryerson/frontend.media";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import Moment from "react-moment";
import { renderBasicText } from "@components/Shared/model/Contentful";
import ContactUsForm from "@components/Shared/ContactUsRefactor/ContactUs";

export type PodcastMin = {
	slug: string;
	title: string;
	videoThumbnail: {
		file: {
			url: string;
		};
	};
	publishDate: string;
	node_locale: "en-US" | "fr-CA" | "es-ES";
};

export type Podcast = PodcastMin & {
	summary: {
		summary: string;
	};
	summaryImage: {
		file: {
			url: string;
		};
	};
	itunesUrl: string;
	spotifyUrl: string;
	amazonUrl: string;
	youtubeUrl: string;
	videoId: string;
	transcript: any;
	seoTitle: string;
	seoDescription: string;
	featuredGuests: PodcastGuest[];
};

export type PodcastGuest = {
	name: string;
	title: string;
	portrait: {
		file: {
			url: string;
		};
	};
};

const staticContent: LanguageContent<any> = {
	en: {
		backToMain: "Back to Cup O' Joe",
		releasedOn: "Released on:",
		listenOn: "Listen on:",
		episodeSummary: "Episode Summary",
		featuring: "Featuring",
		transcript: "Transcript",
	},
	fr: {
		backToMain: "Retour à Cup O' Joe",
		releasedOn: "Publié le:",
		listenOn: "Écoutez sur:",
		episodeSummary: "Résumé de l'épisode",
		featuring: "Avec",
		transcript: "Transcription",
	},
	es: {
		backToMain: "Volver a Cup O' Joe",
		releasedOn: "Publicado en:",
		listenOn: "Escuchar en:",
		episodeSummary: "Resumen del episodio",
		featuring: "Con",
		transcript: "Transcripción",
	},
};

const PodcastDetailPage: React.FC<LanguageContent<Podcast>> = (content) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="32px">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<FlexItem>
							<Breadcrumb type="tertiary" size="xs" />
						</FlexItem>
						<FlexItem>
							<Link to="/metal-resources/metal-market-intelligence/podcast">
								<Typography
									variant="div"
									size="sm"
									type="tertiary"
									css={css`
										text-align: right;
									`}
								>
									<div
										css={css`
											display: inline-block;
											vertical-align: middle;
											height: 24px;
											width: 24px;
											border-radius: 24px;
											line-height: 24px;
											border: 2px solid ${theme.colors.primary.lightGray};
											box-sizing: border-box;
											text-align: center;
											margin-right: 10px;
											padding-right: 1px;
										`}
									>
										<Icon
											icon="chevron-left"
											size="xs"
											color={theme.colors.primary.lightGray}
										/>
									</div>
									{staticContent[language].backToMain}
								</Typography>
							</Link>
						</FlexItem>
					</Flex>
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						css={css`
							margin-top: 48px;
							margin-bottom: 40px;
						`}
					>
						<FlexItem
							css={css`
								width: calc(100% - 560px);
							`}
						>
							<Typography
								variant="h3"
								type="tertiary"
								css={css`
									font-size: 20px !important;
									font-weight: 400 !important;
								`}
							>
								Cup O' Joe
							</Typography>
							<Typography
								variant="h1"
								css={css`
									margin-top: 12px;
									font-size: 37px !important;
									max-width: 560px;
									line-height: 45px !important;
								`}
								type="tertiary"
							>
								{content[language].title}
							</Typography>
							<Typography
								variant="div"
								type="tertiary"
								size="sm"
								css={css`
									margin-top: 16px;
								`}
							>
								{staticContent[language].releasedOn}{" "}
								<Moment format="MMM D, YYYY">
									{content[language].publishDate}
								</Moment>
							</Typography>
							<Typography
								variant="div"
								type="tertiary"
								size="sm"
								css={css`
									margin-top: 40px;
								`}
							>
								{staticContent[language].listenOn}
							</Typography>
							<div
								css={css`
									width: 100%;
									display: block;
									margin-top: 10px;
									height: auto;
								`}
							>
								<Link
									to={content[language].itunesUrl}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/ApplePodcast.png"
										alt="Apple Podcast"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={content[language].spotifyUrl}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/SpotifyPodcast.png"
										alt="Spotify"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={content[language].amazonUrl}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/AmazonPodcast.png"
										alt="Amazon Music"
										css={css`
											display: inline-block;
											vertical-align: middle;
											margin-right: 20px;
										`}
									/>
								</Link>
								<Link
									to={content[language].youtubeUrl}
									target={"_blank"}
									gatsby={false}
								>
									<img
										src="/images/metal-resources/metal-market-intelligence/podcast/YoutubePodcast.png"
										alt="YouTube"
										css={css`
											display: inline-block;
											vertical-align: middle;
										`}
									/>
								</Link>
							</div>
						</FlexItem>
						<FlexItem
							css={css`
								width: 560px;
								height: 315px;
							`}
						>
							<Video
								imageUrl={content[language].videoThumbnail.file.url}
								videoId={content[language].videoId}
								buttonOrientation="center"
							/>
						</FlexItem>
					</Flex>
				</ContentSection>
				<ContentSection type="primary" vPadding="40px">
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						css={css`
							margin-bottom: 40px;
						`}
					>
						<FlexItem>
							<Typography variant="h2" type="primary">
								{staticContent[language].episodeSummary}
							</Typography>
							<Typography
								variant="h3"
								type="primary"
								color={theme.colors.primary.gray}
								weight="normal"
								css={css`
									margin-top: 36px;
									letter-spacing: -1px;
									max-width: 460px;
								`}
							>
								{content[language].summary.summary}
							</Typography>
						</FlexItem>
						<FlexItem>
							<img
								src={content[language].summaryImage.file.url}
								alt={staticContent[language].episodeSummary}
								css={css`
									width: 560px;
									height: 360px;
									display: block;
									object-fit: cover;
								`}
							/>
						</FlexItem>
					</Flex>
				</ContentSection>
				<ContentSection type="secondary" vPadding="40px">
					<Flex
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						css={css`
							gap: 20px;
							margin-bottom: 40px;
						`}
					>
						<FlexItem
							css={css`
								width: 260px;
							`}
						>
							<Typography variant="h2" type="secondary">
								{staticContent[language].featuring}
							</Typography>
						</FlexItem>
						<FlexItem>
							<Flex
								direction="row"
								justifyContent="flex-start"
								wrap={"wrap"}
								css={css`
									gap: 40px;
								`}
							>
								{content[language].featuredGuests.map(
									(guest: PodcastGuest, index: number) => {
										return (
											<FlexItem
												key={index}
												css={css`
													width: 250px;
													height: 160px;
												`}
											>
												<img
													src={guest.portrait.file.url}
													alt={guest.name}
													css={css`
														width: 60px;
														height: 60px;
														object-fit: cover;
														display: block;
														border-radius: 60px;
													`}
												/>
												<Typography
													variant="div"
													size="md"
													type="secondary"
													color={theme.colors.secondary.header}
													weight="bold"
													css={css`
														margin-top: 20px;
														margin-bottom: 8px;
													`}
												>
													{guest.name}
												</Typography>
												<Typography
													variant="div"
													size="md"
													type="secondary"
												>
													{guest.title}
												</Typography>
											</FlexItem>
										);
									}
								)}
							</Flex>
						</FlexItem>
					</Flex>
				</ContentSection>
				<ContentSection type="primary" vPadding="80px">
					<Typography
						variant="h2"
						type="primary"
						css={css`
							margin-bottom: 40px;
						`}
					>
						{staticContent[language].transcript}
					</Typography>
					<div>{renderBasicText(content[language].transcript.raw, "primary")}</div>
				</ContentSection>
				<ContactUsForm background="secondary" withImage={false} />
				<ContentSection type="secondary" vPadding="20px" />
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="24px">
					<Breadcrumb type="tertiary" size="xs" />

					<Link to="/metal-resources/metal-market-intelligence/podcast">
						<Typography
							variant="div"
							size="sm"
							type="tertiary"
							css={css`
								margin-top: 16px;
								margin-bottom: 20px;
							`}
						>
							<div
								css={css`
									display: inline-block;
									vertical-align: middle;
									height: 24px;
									width: 24px;
									border-radius: 24px;
									line-height: 24px;
									border: 2px solid ${theme.colors.primary.lightGray};
									box-sizing: border-box;
									text-align: center;
									margin-right: 10px;
									padding-right: 1px;
								`}
							>
								<Icon
									icon="chevron-left"
									size="xs"
									color={theme.colors.primary.lightGray}
								/>
							</div>
							{staticContent[language].backToMain}
						</Typography>
					</Link>

					<Typography
						variant="h4"
						type="tertiary"
						css={css`
							font-weight: 400 !important;
						`}
					>
						Cup O' Joe
					</Typography>
					<Typography
						variant="h1"
						css={css`
							margin-top: 12px;
							font-size: 37px !important;
						`}
						type="tertiary"
					>
						{content[language].title}
					</Typography>
					<Typography
						variant="div"
						type="tertiary"
						size="sm"
						css={css`
							margin-top: 16px;
						`}
					>
						{staticContent[language].releasedOn}{" "}
						<Moment format="MMM D, YYYY">{content[language].publishDate}</Moment>
					</Typography>
					<Typography
						variant="div"
						type="tertiary"
						size="sm"
						css={css`
							margin-top: 40px;
						`}
					>
						{staticContent[language].listenOn}
					</Typography>
					<div
						css={css`
							width: 100%;
							display: block;
							margin-top: 10px;
							height: auto;
							margin-bottom: 40px;
						`}
					>
						<Link to={content[language].itunesUrl} target={"_blank"} gatsby={false}>
							<img
								src="/images/metal-resources/metal-market-intelligence/podcast/ApplePodcast.png"
								alt="Apple Podcast"
								css={css`
									display: inline-block;
									vertical-align: middle;
									margin-right: 20px;
								`}
							/>
						</Link>
						<Link to={content[language].spotifyUrl} target={"_blank"} gatsby={false}>
							<img
								src="/images/metal-resources/metal-market-intelligence/podcast/SpotifyPodcast.png"
								alt="Spotify"
								css={css`
									display: inline-block;
									vertical-align: middle;
									margin-right: 20px;
								`}
							/>
						</Link>
						<Link to={content[language].amazonUrl} target={"_blank"} gatsby={false}>
							<img
								src="/images/metal-resources/metal-market-intelligence/podcast/AmazonPodcast.png"
								alt="Amazon Music"
								css={css`
									display: inline-block;
									vertical-align: middle;
									margin-right: 20px;
								`}
							/>
						</Link>
						<Link to={content[language].youtubeUrl} target={"_blank"} gatsby={false}>
							<img
								src="/images/metal-resources/metal-market-intelligence/podcast/YoutubePodcast.png"
								alt="YouTube"
								css={css`
									display: inline-block;
									vertical-align: middle;
								`}
							/>
						</Link>
					</div>
					<div
						css={css`
							display: block;

							width: 480px;
							height: 320px;
							margin-bottom: 60px;
							margin-left: auto;
							margin-right: auto;
							@media (max-width: ${theme.breakpoints.sm}) {
								width: 300px;
								height: 200px;
							}
							@media (max-width: ${theme.breakpoints.md}; min-width: ${theme
									.breakpoints.sm}) {
								width: 480px;
								height: 320px;
							}
							@media (max-width: ${theme.breakpoints.lg}; min-width: ${theme
									.breakpoints.md}) {
								width: 560px;
								height: 360px;
							}
						`}
					>
						<Video
							imageUrl={content[language].videoThumbnail.file.url}
							videoId={content[language].videoId}
							buttonOrientation="center"
						/>
					</div>
				</ContentSection>
				<ContentSection type="primary" vPadding="32px">
					<Typography variant="h2" type="primary">
						{staticContent[language].episodeSummary}
					</Typography>
					<Typography
						variant="h3"
						type="primary"
						color={theme.colors.primary.gray}
						weight="normal"
						css={css`
							margin-top: 32px;
							letter-spacing: -1px;
							margin-bottom: 20px;
						`}
					>
						{content[language].summary.summary}
					</Typography>
					<img
						src={content[language].summaryImage.file.url}
						alt={staticContent[language].episodeSummary}
						css={css`
							width: 300px;
							height: 200px;
							display: block;
							margin: 0 auto;
							object-fit: cover;
						`}
					/>
				</ContentSection>
				<ContentSection type="secondary" vPadding="40px">
					<Typography
						variant="h2"
						type="secondary"
						css={css`
							margin-bottom: 24px;
						`}
					>
						{staticContent[language].featuring}
					</Typography>

					{content[language].featuredGuests.map((guest: PodcastGuest, index: number) => {
						return (
							<div
								key={index}
								css={css`
									width: 300px;
									height: 80px;
									margin-bottom: 20px;
								`}
							>
								<img
									src={guest.portrait.file.url}
									alt={guest.name}
									css={css`
										width: 80px;
										height: 80px;
										object-fit: cover;
										display: inline-block;
										border-radius: 80px;
										margin-right: 10px;
										vertical-align: top;
									`}
								/>
								<div
									css={css`
										display: inline-block;
										vertical-align: top;
										width: calc(100% - 90px);
									`}
								>
									<Typography
										variant="div"
										size="md"
										type="secondary"
										color={theme.colors.secondary.header}
										weight="bold"
										css={css`
											margin-bottom: 4px;
										`}
									>
										{guest.name}
									</Typography>
									<Typography variant="div" size="md" type="secondary">
										{guest.title}
									</Typography>
								</div>
							</div>
						);
					})}
				</ContentSection>
				<ContentSection type="primary" vPadding="40px">
					<Typography
						variant="h2"
						type="primary"
						css={css`
							margin-bottom: 30px;
						`}
					>
						{staticContent[language].transcript}
					</Typography>
					<div>{renderBasicText(content[language].transcript.raw, "primary")}</div>
				</ContentSection>
				<ContactUsForm background="secondary" withImage={false} />
				<ContentSection type="secondary" vPadding="10px" />
			</Media>
		</>
	);
};

export default PodcastDetailPage;
